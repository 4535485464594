import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b112a0b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "Breadcrumb" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "textArea" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = {
  key: 0,
  class: "slash"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.breadcrumbData, (breadcrumbItem, breadcrumbIndex) => {
          return (_openBlock(), _createElementBlock("div", { key: breadcrumbIndex }, [
            _createVNode(_component_router_link, {
              to: `${breadcrumbItem.path}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(`${breadcrumbItem.text}`), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            (($props.breadcrumbData.length - 1) !== breadcrumbIndex)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(`/`)))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}